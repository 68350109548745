<!--
 * @Description: 下单、预定
 * @Author: 琢磨先生
 * @Date: 2022-07-07 13:50:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-28 03:57:17
-->
<template>
  <el-drawer
    v-model="visibleDrawer"
    size="800px"
    :destroy-on-close="true"
    :show-close="true"
    @closed="onClosed"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <template #header>
      <h4 class="title">预定</h4>
    </template>
    <el-form :model="form" ref="form" :rules="rules">
      <el-divider direction="horizontal" content-position="left">
        <b>基本信息</b>
      </el-divider>
      <el-row>
        <el-col :span="8" :offset="0">
          <el-form-item label="姓名" prop="contact_name">
            <el-input v-model="form.contact_name" placeholder></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="手机号" prop="mobile_phone">
            <el-input v-model="form.mobile_phone" placeholder></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="渠道" prop="ota_id">
            <el-select v-model="form.ota_id" placeholder>
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in ota_list"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" :offset="0">
          <el-form-item label="类型" prop="type">
            <el-select v-model="form.type" placeholder>
              <el-option
                :label="item.text"
                :value="item.value"
                v-for="item in enums.order_types"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider direction="horizontal" content-position="left">
        <b>房间信息</b>
      </el-divider>
      <div class="room_list">
        <div v-for="(item, i) in accomRoomList" :key="item">
          <div class="item">
            <el-date-picker
              v-model="item.check_in_date"
              type="date"
              style="width: 140px"
              :clearable="false"
              value-format="YYYY-MM-DD"
              placeholder="选择日期"
              @change="dateChange"
            ></el-date-picker>
            <el-popover placement="bottom" :width="200" trigger="click">
              <template #reference>
                <el-button style="width: 100px">{{ item.days }}晚</el-button>
              </template>
              <el-input-number v-model="item.days" :min="1" @change="daysChange" />
            </el-popover>
            <el-select
              v-model="item.store_id"
              placeholder
              filterable
              remote
              style="width: 200px"
              :remote-method="remoteStore"
              @change="storeChange(item)"
            >
              <el-option
                :label="`${item.house_no}/${item.house_name}`"
                :value="item.id"
                :key="item.id"
                v-for="item in storeList"
              ></el-option>
            </el-select>

            <el-popover placement="bottom" :width="640" trigger="click">
              <template #reference>
                <el-input
                  v-model="item.decimal_amount"
                  placeholder
                  style="width: 100px"
                  @input="amountChange(item)"
                >
                  <template #prefix>￥</template>
                </el-input>
              </template>
              <!-- 房间金额 -->
              <div class="date_price_height">
                <div class="date_price_grid">
                  <div class="grid_item" v-for="x in item.datePrices" :key="x">
                    <div class="date">{{ x.date }}</div>
                    <div class="price">
                      <el-input
                        v-model="x.decimal_price"
                        placeholder
                        style="width: 100px"
                        @change="priceChange(item)"
                      >
                        <template #prefix>￥</template>
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
            </el-popover>
            <el-popover placement="bottom" :width="760" trigger="click">
              <template #reference>
                <el-button type="primary" link size="small" style="margin-left: 10px">
                  入住人({{
                  item.residents ? item.residents.length : 0
                  }})
                </el-button>
              </template>
              <el-table :data="item.residents" border>
                <el-table-column label="姓名" width="120">
                  <template #default="scope">
                    <el-input v-model="scope.row.name" placeholder="请输入姓名"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="手机号" width="160">
                  <template #default="scope">
                    <el-input v-model="scope.row.mobile_phone" clearable placeholder="请输入手机号"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="证件类型" width="120">
                  <template #default="scope">
                    <el-select v-model="scope.row.card_type" placeholder>
                      <el-option
                        :label="x.text"
                        :value="x.value"
                        v-for="x in enums.card_types"
                        :key="x.value"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="证件号">
                  <template #default="scope">
                    <el-input v-model="scope.row.card_no" clearable placeholder="请输入证件号码"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label width="80">
                  <template #header>
                    <el-button round type="success" size="small" @click="addResident(item)">+添加</el-button>
                  </template>
                  <template #default="scope">
                    <el-icon
                      style="margin-left: 20px"
                      @click="item.residents.splice(scope.$index, 1)"
                    >
                      <Delete />
                    </el-icon>
                  </template>
                </el-table-column>
              </el-table>
            </el-popover>

            <el-icon style="margin-left: 20px" @click="onDelRoom(i)">
              <Delete />
            </el-icon>
          </div>
          <div
            class="help-block"
            v-if="item.unusable"
            style="color: #f56c6c"
          >存在无法预定的日期,{{ item.occupyDates.join(",") }}</div>
        </div>

        <div class="buttons">
          <el-button type="primary" plain @click="onAddRoom">+添加房间</el-button>
        </div>
      </div>
      <!-- 收款信息 -->
      <div>
        <el-divider direction="horizontal" content-position="left">
          <b>收款信息</b>
        </el-divider>
        <el-table :data="receiptList" border :show-header="false">
          <el-table-column label width="140">
            <template #default="scope">
              <el-select v-model="scope.row.type" placeholder>
                <el-option
                  :label="item.text"
                  :value="item.value"
                  v-for="item in enums.receipt_types"
                  :key="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label width="160">
            <template #default="scope">
              <el-select v-model="scope.row.pay_way" placeholder>
                <el-option
                  :label="item.text"
                  :value="item.value"
                  v-for="item in enums.pay_way_types"
                  :key="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label width="140">
            <template #default="scope">
              <el-input v-model="scope.row.decimal_amount" clearable placeholder="金额">
                <template #prefix>￥</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label>
            <template #default="scope">
              <el-date-picker
                v-model="scope.row.date"
                :clearable="false"
                placeholder="付款日期"
                style="width: 120px"
                value-format="YYYY-MM-DD"
                :disabled-date="(date) => date > new Date()"
              ></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label width="80">
            <template #default="scope">
              <el-popover :width="300" trigger="click">
                <template #reference>
                  <el-button type="primary" link size="small">备注</el-button>
                </template>
                <el-input v-model="scope.row.remark" placeholder="备注信息" type="textarea"></el-input>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label width="80">
            <template #default="scope">
              <el-icon style="margin-left: 20px" @click="receiptList.splice(scope.$index, 1)">
                <Delete />
              </el-icon>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" link style="margin-top: 20px" @click="addReceipt">+添加收款</el-button>
      </div>

      <!-- 收款信息  END-->
      <el-divider direction="horizontal" content-position="left">备注信息</el-divider>

      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" rows="4" placeholder="备注信息"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="flex-row flex-between">
        <div class="total">
          订单金额：
          <span>¥{{ totalAmount }}</span>
        </div>

        <el-button type="primary" :loading="saving" :disabled="loading" @click="onSubmit">提交订单</el-button>
      </div>
    </template>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
import ota_api from "@/http/ota_api";
export default {
  data() {
    return {
      visibleDrawer: true,
      saving: false,
      loading: false,
      form: {},
      rules: {
        contact_name: [{ required: true, message: "请输入", trigger: "blur" }],
        mobile_phone: [{ required: true, message: "请输入", trigger: "blur" }],
        ota_id: [{ required: true, message: "请选择", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      enums: {},
      //渠道
      ota_list: [],
      //传递进来的选择数据
      // checkRoomList: [],
      //检测选择的房源数据返回结果列表
      accomRoomList: [],
      //房源库存
      storeList: [],
      //收款数据列表
      receiptList: [],
      //总额
      totalAmount: 0,
    };
  },
  props: ["room_list"],
  emits: ["close"],
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    ota_api.get_otas().then((res) => {
      if (res.code == 0) {
        this.ota_list = res.data;
      }
    });

    if (Array.isArray(this.room_list) && this.room_list.length > 0) {
      this.$http
        .post("seller/v1/houseOrder/group_check", this.room_list)
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.accomRoomList = res.data;
            this.storeList = res.data.map((o) => {
              return {
                id: o.store_id,
                house_no: o.house_no,
                house_name: o.house_name,
              };
            });
          }
        });
    }
  },
  methods: {
    /**
     * 分组
     */
    handleGroup(arr) {
      var obj = {};
      var groups = [];
      arr.forEach((item) => {
        if (!obj[item.id]) {
          obj[item.id] = item;
          groups.push({
            key: item.id,
            items: [item],
          });
        } else {
          groups.find((v) => {
            if (v.key === item.id) {
              v.items.push(item);
            }
          });
        }
      });
      return groups;
    },

    /**
     * 日期选择更改
     */
    dateChange() {
      this.onGetCheck();
    },
    /**
     * 天数更改
     */
    daysChange() {
      this.onGetCheck();
    },

    /**
     * 获取check的数据
     */
    onGetCheck() {
      this.loading = true;
      this.accomRoomList.forEach((item) => {
        if (!item.days) {
          item.days = 1;
        } else {
          item.days = parseInt(item.days, 0);
        }
      });
      this.$http
        .post("seller/v1/houseOrder/check", this.accomRoomList)
        .then((res) => {
          this.totalAmount = 0;
          if (res.code == 0) {
            for (var i = 0; i < this.accomRoomList.length; i++) {
              var item = res.data[i];
              this.accomRoomList[i].decimal_amount = item.decimal_amount;
              this.accomRoomList[i].unusable = item.unusable;
              this.accomRoomList[i].occupyDates = item.occupyDates;
              this.accomRoomList[i].datePrices = item.datePrices;
              this.accomRoomList[i].house_id = item.house_id;
              this.totalAmount += item.decimal_amount;
            }
          }
          this.loading = false;
        });
    },

    /**
     * 关键字搜索房间库存
     */
    remoteStore(q) {
      this.$http("seller/v1/common/select-housestore?q=" + q).then((res) => {
        if (res.code == 0) {
          this.storeList = this.storeList.concat(res.data);
        }
      });
    },
    /**
     * 房间库存选择更改
     */
    storeChange() {
      this.onGetCheck();
    },
    /**
     * 添加房间
     */
    onAddRoom() {
      var item = {};
      if (this.accomRoomList.length > 0) {
        item = Object.assign({}, this.accomRoomList[0]);
      } else if (this.room_list.length > 0) {
        item = Object.assign({}, this.room_list[0]);
      } else {
        item = {
          days: 1,
        };
      }
      delete item.store_id;
      delete item.house_id;
      this.accomRoomList.push(item);
      this.onGetCheck();
    },
    /**
     * 删除房间
     */
    onDelRoom(i) {
      this.accomRoomList.splice(i, 1);
    },

    /**
     * 添加入住人
     */
    addResident(item) {
      if (!item.residents) {
        item.residents = [];
      }
      item.residents.push({
        name: "",
        mobile_phone: "",
        card_no: "",
        card_type: 0,
      });
    },
    /**
     * 添加收款
     */
    addReceipt() {
      this.receiptList.push({
        date: "",
        remark: "",
        amount: "",
        pay_way: 0,
        type: 0,
      });
    },

    /**
     * 单价更改
     */
    priceChange(item) {
      console.log(item.datePrices);
      item.amount = 0;
      item.datePrices.forEach((x) => {
        if (!isNaN(x.price)) {
          item.amount += parseFloat(x.price);
        }
      });
      this.caclTotalAmount();
    },
    /**
     * 每个房源的总价更改
     */
    amountChange(item) {
      if (!isNaN(item.decimal_amount)) {
        var price = parseFloat(
          (item.decimal_amount / item.datePrices.length).toFixed(2)
        );
        var temp_amount = 0;
        item.datePrices.forEach((x) => {
          x.decimal_price = price;
          temp_amount += x.decimal_price;
        });
        item.datePrices[0].price = parseFloat(
          (
            item.decimal_amount -
            temp_amount +
            item.datePrices[0].decimal_price
          ).toFixed(2)
        );
        this.caclTotalAmount();
      }
    },

    /**
     * 计算总金额
     */
    caclTotalAmount() {
      this.totalAmount = 0;
      var temp = 0;
      this.accomRoomList.forEach((item) => {
        temp += parseFloat(item.amount);
      });
      this.totalAmount = temp.toFixed(2);
    },

    /**
     * 关闭
     */
    onClosed() {
      this.$emit("close");
    },

    /**
     *  提交下订单
     */
    onSubmit() {
      this.form.accoms = this.accomRoomList;
      this.form.receipts = this.receiptList;

      for (var i = 0; i < this.form.accoms.length; i++) {
        var item = this.form.accoms[i];
        if (!item.store_id) {
          this.$message.error("请选择房屋");
          return;
        }
        if (!item.decimal_amount) {
          this.$message.error("请输入金额");
          return;
        }
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/houseOrder/submit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.dialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.visibleDrawer = false;
                this.receiptList = [];
                this.form = {};
                this.$emit('close')
              } else {
                this.$alert(res.msg, "提醒");
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.title {
  line-height: 23px;
  font-size: 18px;
  margin: 0;
}

.room_list .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.room_list .item .el-date-picker {
  width: 80px;
}
.room_list .item .el-select {
  flex-grow: 1;
  width: auto;
}
.room_list .item .el-input {
  width: 140px;
}
.room_list .buttons {
  margin-top: 10px;
}

.total {
  font-size: 18;
  font-weight: 600;
}
.total span {
  color: #409eff;
}

/* 价格列表 */
.date_price_height {
  max-height: 500px;
  overflow-y: auto;
}

.date_price_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 20px;
  column-gap: 20px;
}

.date_price_grid .grid_item {
}

.date_price_grid .grid_item .date {
  font-weight: 600;
}
</style>